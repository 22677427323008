import React from "react";

const App = () => {
  return (
    <div className="bg-blue-100 min-h-screen flex items-center justify-center p-8">
      {/* Card Container */}
      <div className="bg-white rounded-xl shadow-xl p-8 max-w-5xl w-full">
        {/* Top Section: Header and HR */}
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            HI, I'M VARUN.
          </h1>
          <p className="text-lg font-medium text-gray-500">
            FOUNDER. PRODUCT LEADER. ENGINEER. EDUCATOR.
          </p>
          <hr className="border-gray-300 my-6 w-24 mx-auto" />
        </div>

        {/* Bottom Section: Two Columns */}
        <div className="flex flex-col lg:flex-row lg:space-x-12 space-y-8 lg:space-y-0">
          {/* Left Column: Text Content */}
          <div className="lg:w-2/3">
            <p className="text-md text-gray-600 mb-4">
              I believe that thoughtfully-built tech can create meaningful
              learning experiences.{" "}
            </p>
            <p className="text-md text-gray-600 mb-4">I am a...</p>
            <ul className="text-md pl-4 text-gray-600 space-y-2">
              <li>
                <strong>Founder:</strong> Co-founder & CEO at{" "}
                <a
                  href="https://www.litlab.ai"
                  target="_blank"
                  rel="noreferrer"
                  className="text-green-600 underline"
                >
                  LitLab.ai
                </a>
                . Previously started & sold{" "}
                <a
                  href="https://www.edsurge.com/news/2015-02-19-renaissance-learning-acquires-uclass-a-dropbox-for-education"
                  target="_blank"
                  rel="noreferrer"
                  className="text-green-600 underline"
                >
                  UClass
                </a>
              </li>
              <li>
                <strong>Product Leader:</strong> Head of Product & Growth at
                Mystery.org
              </li>
              <li>
                <strong>Engineer:</strong> Led a high-performing dev team as CTO
              </li>
              <li>
                <strong>Educator:</strong> Taught math at a public high school
              </li>
            </ul>
            <p className="text-md text-gray-600 mt-6">
              I live in Brooklyn, NY with my wonderful partner, Abi, and our
              very sweet dog, Jupiter. In my spare time, I enjoy reading,
              running and cycling around Prospect Park, completing 1000-piece
              puzzles, making homemade chai, and being outdoors. In what feels
              like a lifetime ago, I used to hand-build ceramics, dance (I've
              performed at 4 Warriors halftime shows), and write personal
              essays.
            </p>
          </div>

          {/* Right Column: Image */}
          <div className="lg:w-1/3 flex justify-center">
            <img
              src={`${process.env.PUBLIC_URL}/boca.jpg`}
              alt="Varun"
              className="rounded-lg shadow-lg w-80 h-80 object-cover"
            />
          </div>
        </div>
        <div>
          <p className="text-md text-gray-600 mt-6">
            Want to connect? Send me a message on{" "}
            <a
              href="https://www.linkedin.com/in/vrgulati/"
              target="_blank"
              rel="noreferrer"
              className="text-green-600 underline"
            >
              LinkedIn
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default App;
